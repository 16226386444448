<template>
  <div class="row">
    <div class="col-12 col-lg-6 c">
      <div class="card">
        <div class="card-header">
          <h4>الإعدادات</h4>
        </div>
        <div class="card-body" v-if="info">
          <div class="form-group">
            <label for=""><h3>إسم المدرسة</h3></label>
            <input
              type="text"
              class="form-control form-control-lg"
              ref="school_title"
              :value="info.school_title"
            />
          </div>
          <div class="form-group" style="display: none">
            <label for=""><h3>السجل المدني</h3></label>
            <input
              type="text"
              class="form-control form-control-lg"
              ref="number"
              :value="info.number"
            />
          </div>
          <div class="form-group">
            <label for=""><h3>إسم مدير المدرسة</h3></label>
            <input
              type="text"
              class="form-control form-control-lg"
              ref="admin"
              :value="info.admin"
            />
          </div>
          <div class="form-group">
            <label for=""><h3>إسم الرئيس المباشر</h3></label>
            <input
              type="text"
              class="form-control form-control-lg"
              ref="direct_boss"
              :value="info.direct_boss"
            />
          </div>
          <hr />
          <h4>اعدادات الsms لارسال المسائلات وغيرها.</h4><br>
          <div class="form-group">
            <h3 for="">بوابة الإرسال (SMS)</h3>
            <select
              class="form-control form-control-lg"
              name=""
              id=""
              ref="sms_portal"
            >
              <option
                v-for="portal in portals"
                v-bind:key="portal.code"
                :selected="portal.code == info.sms_portal ? true : false"
                :value="portal.code"
              >
                {{ portal.title }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for=""><h3>إسم المستخدم (SMS)</h3></label>
            <input
              type="text"
              class="form-control form-control-lg"
              ref="sms_username"
              :value="info.sms_username"
            />
          </div>
          <div class="form-group">
            <label for=""><h3>كلمة المرور (SMS)</h3></label>
            <input
              type="text"
              class="form-control form-control-lg"
              ref="sms_password"
              :value="info.sms_password"
            />
          </div>
          <div class="form-group">
            <label for=""><h3>إسم المرسل (SMS)</h3></label>
            <input
              type="text"
              class="form-control form-control-lg"
              ref="sms_sendername"
              :value="info.sms_sendername"
            />
          </div>
          <hr>
          <h4>
            اعدادات ارسال التاخر (للبصمة فقط) <small>يتم الارسال من اعدادات الارسال في نظام التحضير الذكي</small>
          </h4>
          <br>
          <div class="form-check">
            <label class="form-check-label">
              <input type="checkbox" class="form-check-input" value="1" v-model="send_teachers_late">
              <h5>
                ارسال التاخر تلقائياً للمعلم
              </h5>
            </label>
          </div><br>
          <div class="form-group">
            <h5 for="">رسالة التاخر</h5>
            <textarea class="form-control" name="" id="" rows="3" v-model="teachers_late_message"></textarea>
          </div>
          <div class="col-12 text-center g">
            <button class="btn btn-lg btn-primary" @click="save()">حفظ</button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12"></div>
    <div class="col-12 col-lg-6 c" v-if="viewdelete">
      <div class="card">
        <div class="card-header">
          <h4>حذف بيانات النظام</h4>
        </div>
        <div class="card-body">
          <p>اختر التقارير والسجلات المراد حذفها:</p>
          <div class="form-check g">
            <h5 class="form-check-label">
              <input
                type="checkbox"
                class="form-check-input"
                v-model="selected"
                value="teachers"
              />
              بيانات المعلمين
            </h5>
          </div>
          <div class="form-check g">
            <h5 class="form-check-label">
              <input
                type="checkbox"
                class="form-check-input"
                v-model="selected"
                value="reports"
              />
              تقارير الغياب والحضور اليومي للمعلمين
            </h5>
          </div>
          <div class="form-check g">
            <h5 class="form-check-label">
              <input
                type="checkbox"
                class="form-check-input"
                v-model="selected"
                value="ask"
              />
              مسائلات الغياب والتأخر
            </h5>
          </div>
          <div class="col-12 text-center" @click="deleteNow()">
            <button class="btn btn-danger">حذف السجلات المحددة نهائياً</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from "bootstrap-vue";
const axios = require("axios");
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BCard,
    BCardText,
    BLink,
  },
  data() {
    return {
      send_teachers_late: false,
      teachers_late_message: "الاستاذ {name} تم تسجيل الحضور متأخر الساعة {time}",
      info: {},
      portals: [],
      selected: [],
      viewdelete: false,
    };
  },
  methods: {
    save() {
      var _g = this;
      axios
        .post(localStorage.getItem("api") + "/teachers/save-info", {
          jwt: localStorage.getItem("jwt"),
          school_title: this.$refs.school_title.value,
          number: this.$refs.number.value,
          admin: this.$refs.admin.value,
          direct_boss: this.$refs.direct_boss.value,
          sms_portal: this.$refs.sms_portal.value,
          sms_username: this.$refs.sms_username.value,
          sms_password: this.$refs.sms_password.value,
          sms_sendername: this.$refs.sms_sendername.value,
          teachers_late_message: this.teachers_late_message,
          send_teachers_late: this.send_teachers_late
        })
        .then(function (r) {
          if (r.data.status == 200) {
            _g.$toast({
              component: ToastificationContent,
              props: {
                title: "حدث خطأ JWT",
                icon: "WarningIcon",
                variant: "danger",
              },
            });
          } else {
            _g.$toast({
              component: ToastificationContent,
              props: {
                title: "تم الحفظ بنجاح",
                icon: "CheckIcon",
                variant: "success",
              },
            });
          }
        })
        .catch(function () {
          _g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدث خطأ",
              icon: "WarningIcon",
              variant: "danger",
            },
          });
        });
    },
    deleteNow() {
      var g = this,
        _g = g;
      if (
        confirm("متأكد من حذف البيانات نهائياً؟ لن تتمكن من استعادها مرة اخرى.")
      ) {
        axios
          .post(localStorage.getItem("api") + "/teachers/factory-reset", {
            jwt: localStorage.getItem("jwt"),
            selected: g.selected,
          })
          .then(function (r) {
            if (r.data.status == 200) {
              _g.$toast({
                component: ToastificationContent,
                props: {
                  title: "حدث خطأ JWT",
                  icon: "WarningIcon",
                  variant: "danger",
                },
              });
            } else {
              _g.$toast({
                component: ToastificationContent,
                props: {
                  title: "تم بنجاح",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            }
          })
          .catch(function () {
            _g.$toast({
              component: ToastificationContent,
              props: {
                title: "حدث خطأ",
                icon: "WarningIcon",
                variant: "danger",
              },
            });
          });
      }
    },
  },
  created() {
    var _g = this;
    if (!localStorage.getItem("per")) {
      _g.viewdelete = true;
    }
    axios
      .post(localStorage.getItem("api") + "/teachers/info", {
        jwt: localStorage.getItem("jwt"),
      })
      .then(function (r) {
        if (r.data.status == 200) {
          _g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدث خطأ JWT",
              icon: "WarningIcon",
              variant: "danger",
            },
          });
        } else {
          if (!r.data.response.school_title) {
            r.data.response.school_title = "";
          }
          if (!r.data.response.number) {
            r.data.response.number = "";
          }
          if (!r.data.response.admin) {
            r.data.response.admin = "";
          }
          if (!r.data.response.direct_boss) {
            r.data.response.direct_boss = "";
          }
          _g.info = r.data.response;
          if(_g.info.teachers_late_message){
            _g.teachers_late_message = _g.info.teachers_late_message
          }
          _g.send_teachers_late = _g.info.send_teachers_late
        }
      })
      .catch(function () {
        _g.$toast({
          component: ToastificationContent,
          props: {
            title: "حدث خطأ",
            icon: "WarningIcon",
            variant: "danger",
          },
        });
      });
    axios
      .post(localStorage.getItem("api") + "/teachers/portals", {
        jwt: localStorage.getItem("jwt"),
      })
      .then(function (r) {
        if (r.data.status == 200) {
          _g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدث خطأ JWT",
              icon: "WarningIcon",
              variant: "danger",
            },
          });
        } else {
          _g.portals = r.data.response;
        }
      })
      .catch(function () {
        _g.$toast({
          component: ToastificationContent,
          props: {
            title: "حدث خطأ",
            icon: "WarningIcon",
            variant: "danger",
          },
        });
      });
  },
};
</script>

<style>
</style>
